import React from "react";
import styled from "styled-components";

import { Container, H2, P } from "@util/standard";
import { colors, colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { moneyFormat, dateFormat } from "@util/helper";
import { Shopify } from "@state/types";
import { Button as CustomButton } from "@global";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  shopify: Shopify;
  setOrder: React.Dispatch<React.SetStateAction<number | null>>;
}

const HeaderRow = styled(Container)`
  font-weight: bold;
  border-bottom: 1px solid ${colors.merlin};
  width: 100%:
  justify-content: stretch;
  margin-bottom: 20px;
`;

const Row = styled(Container)`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colorsRGB.merlin(0.3)};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
`;

const Cell = styled(Container)`
  width: 26.666%;
  padding: 15px;
  text-transform: capitalize;
  &:first-of-type {
    padding: 15px 15px 15px 0;
  }

  &:first-of-type,
  &:last-of-type {
    width: 10%;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    &:first-of-type {
      width: 70%;
    }
    &:last-of-type {
      width: 30%;
    }
  }
`;

const OrderName = styled.a`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 1.5em;
    font-weight: bold;
  }
`;

const Small = styled.small`
  color: ${colorsRGB.merlin(0.6)};
`;

export default function Orders({ shopify, setOrder }: Props) {
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();
  const notSmallScreen = isMobileWidth !== true && isTabletWidth !== true;
  const isSmallScreen = isMobileWidth === true || isTabletWidth === true;

  if (
    !shopify.customer.orders?.edges ||
    shopify.customer.orders?.edges?.length < 1
  ) {
    return (
      <Container flexDirection="column" width="100%">
        <Container
          flexDirection="column"
          alignItems="center"
          width="100%"
          padding="50px 0"
        >
          <P>You don't have any past orders. Make your first order now!</P>
          <CustomButton
            theme="transparent"
            linkTo="/shop-all"
            text="SHOP NOW"
          />
        </Container>
      </Container>
    );
  }

  return (
    <Container flexDirection="column" width="100%">
      <H2>Order History</H2>
      <Container flexDirection="column" width="100%">
        <HeaderRow>
          <Cell>Order</Cell>
          {notSmallScreen && (
            <>
              <Cell>Date</Cell>
              <Cell>Payment Status</Cell>
              <Cell>Fulfillment Status</Cell>
            </>
          )}
          <Cell>Total</Cell>
        </HeaderRow>

        {shopify.customer.orders?.edges.map(order => {
          const orderNode = order?.node;
          if (!orderNode) return;

          return (
            <Row key={orderNode.orderNumber}>
              <Cell onClick={() => setOrder(orderNode.orderNumber)}>
                <OrderName>{orderNode.name}</OrderName>
                {isSmallScreen && (
                  <>
                    <span>{dateFormat(orderNode.processedAt)}</span>
                    <Small>
                      <strong>Payment Status</strong>:{" "}
                      {orderNode.financialStatus?.toLowerCase()}
                    </Small>
                    <Small>
                      <strong>Fulfillment Status</strong>:{" "}
                      {orderNode.fulfillmentStatus === "UNFULFILLED"
                        ? "To be packed"
                        : orderNode.fulfillmentStatus === "FULFILLED"
                        ? "Dispatched"
                        : orderNode.fulfillmentStatus ===
                            "PARTIALLY_FULFILLED" && "Partially dispatched"}
                    </Small>
                  </>
                )}
              </Cell>
              {notSmallScreen && (
                <>
                  <Cell>{dateFormat(orderNode.processedAt)}</Cell>
                  <Cell>{orderNode.financialStatus?.toLowerCase()}</Cell>
                  <Cell>
                    {orderNode.fulfillmentStatus === "UNFULFILLED"
                      ? "To be packed"
                      : orderNode.fulfillmentStatus === "FULFILLED"
                      ? "Dispatched"
                      : orderNode.fulfillmentStatus === "PARTIALLY_FULFILLED" &&
                        "Partially dispatched"}
                  </Cell>
                </>
              )}
              <Cell>
                {orderNode.totalPriceV2?.amount === "0.0"
                  ? "FREE"
                  : moneyFormat(orderNode.totalPriceV2?.amount)}
              </Cell>
            </Row>
          );
        })}
      </Container>
    </Container>
  );
}
